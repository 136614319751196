.img-wrap img.logo {
  position: absolute;
  left: 21%;
  top: 27%;
  right: 0px;
  bottom: 0px;
}
.img-wrap {
  position: relative;
}
.auth-sec .form-wrapper .ter {
  display: flex;
  justify-content: space-between;
}
.auth-sec label.code {
  font-size: 16px;
  color: #000000;
}
.auth-sec label.code span {
  color: #9b9b9b;
  font-size: 13px;
}
.auth-sec .form-wrapper h1 {
  color: #313f2b;
  font-size: 50px;
  margin-bottom: 20px;
}
.auth-sec .form-wrapper h4 {
  color: #888888;
  font-weight: 400;
  margin-bottom: 20px;
}
.auth-sec .form-wrapper h6 {
  color: #000000;
  font-size: 16px;
  margin-bottom: 20px;
}
.auth-sec .form-wrapper h6 span {
  font-weight: 700;
}
.auth-sec .form-wrapper h6 a {
  color: #313f2b;
  font-weight: 700;
}
.auth-sec .form-wrapper form {
  margin-top: 30px;
}
.auth-sec {
  height: 100vh !important;
  overflow: hidden;
}
/* .auth-sec input.form-control {
  padding: 21px 20px;
  color: #b8b8b8;
  border-radius: 7px;
} */
.auth-sec button:hover {
  outline: 2px solid #313f2b;
  border-radius: 0px;
}
.auth-sec button {
  background: #313f2b;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  height: 46px;
  border-radius: 7px;
  transition: all 0.3s ease-in-out;
}
.auth-sec .custom-control-label {
  color: #bbbbbb;
}
.auth-sec a.forgot {
  color: #bbbbbb;
}
.auth-sec .term-sec {
  margin-top: 100px;
}
.auth-sec
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  content: "\f00c";
  font-family: "FontAwesome" !important;
  margin-top: -1px;
  font-size: 13px;
  margin-left: 1px;
}
.auth-sec .custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
  background: #fff !important;
  border-radius: 0px;
  border-color: #bbbbbb !important;
}
.term-sec a {
  color: #313f2b;
}
.field-icon {
  float: right;
  margin-right: 10px;
  margin-top: -28px;
  position: relative;
  z-index: 2;
}

.auth-sec .form-wrapper .enter-mail {
  font-size: 24px;
  line-height: 40px;
  color: #888888;
}
.auth-sec .form-wrapper .enter-mail span {
  font-weight: 600;
}
.auth-sec input.form-control {
  padding: 26px 20px;
  color: #b8b8b8;
  border-radius: 7px;
}
.auth-sec .form-wrapper .otp {
  display: flex;
}
.auth-sec .form-wrapper .otp input {
  margin: 0px 10px 0px 0px;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
  padding: 24px 10px;
}
/* responsoive css starts here */
@media (max-width: 1024px) {
  .auth-sec {
    height: auto !important;
  }
  .auth-sec .img-wrap {
    display: none;
  }
  .auth-sec .form-wrapper {
    padding: 60px 0px;
  }
  .auth-sec input.form-control {
    margin: 5px 0px;
  }
  .field-icon {
    margin-top: -40px;
  }
}
@media (max-width: 375px) {
  .auth-sec h4.enter-mail br {
    display: none;
  }
}
