.join-mission {
  padding: 100px 0px;
}
.join-mission .lock-wrap {
  padding-top: 50px;
}
.join-mission h2 {
  color: #313f2b;
  font-weight: 400;
  font-size: 36px;
}
.join-mission .location-wrapper {
  padding: 33px 20px;
  background: #f5f5f5;
  border-left: 6px solid #313f2b;
  margin: 18px 0px;
}
.join-mission .location-wrapper h3 {
  color: #313f2b;
  font-size: 20px;
  font-weight: 400;
}
.join-mission .location-wrapper h6 {
  color: #9f9f9f;
  font-weight: 400;
  font-size: 16px;
}
.join-mission .location-wrapper h6 i {
  margin-right: 10px;
}
