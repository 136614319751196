.Faq-sec {
  padding: 100px 0px;
}
.Faq-sec h2 {
  color: #313f2b;
  font-weight: 500;
}
.Faq-sec p {
    color: #363636;
}
div#accordion button.btn-link {
  border: 0px;
  background: transparent;
  color: #888888;
  font-weight: 400;
}
#main #faq .card {
  margin-bottom: 15px;
  border: 0;
}
.Faq-sec .card-body {
  color: #363636;
  font-size: 15px;
  line-height: 25px;
  padding-bottom: 0px;
}
#main #faq .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

#main #faq .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  /* background: #ffe472; */
  color: #222;
  padding: 20px;
}

#main #faq .card .card-header .btn-header-link:after {
  content: "\f00d";
  font-family: "FontAwesome";
  font-weight: 300;
  float: right;
}

#main #faq .card .card-header .btn-header-link.collapsed {
  background: #a541bb;
  color: #fff;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\F067";
  font-family: "FontAwesome";
}

/* #main #faq .card .collapsing {
  background: #ffe472;
  line-height: 30px;
} */

#main #faq .card .collapse {
  border: 0;
}
#main #faq .card .collapse.show {
  background: transparent;
}

#main #faq .card .card-header .btn-header-link {
  border: 1px solid #313f2b;
  padding: 10px 15px;
  background: #313f2b;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
}
#main #faq .card .card-header .collapsed {
  border: 1px solid #888888;
  padding: 10px 15px;
  background: #ffffff !important;
  color: #888888 !important;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  margin-bottom: 1px;
}
#main #faq .card .card-header .btn-header-link:hover {
  outline: none;
}
