.planes-section{
    padding: 100px 0px !important;
}
.planes-section .slick-slide {
    opacity: 0;
}
.planes-section .slick-active{
    opacity: 1 !important;
}
/* .planes-section .slick-active img:nth-child(1) {
    height: 400px;
    width: 679px !important;
    object-fit: cover;
    transform: scale(.9);
    position: relative;
    left: -20%;
}
.planes-section .slick-active img:nth-child(3) {
    height: 400px;
    width: 679px !important;
    object-fit: cover;
    transform: scale(.9);
    position: relative;
    right: -20%;
} */
.planes-section .slick-active img {
    height: 400px;
    width: 679px !important;
    object-fit: cover;
    transform: scale(.9);
}
/* .planes-section .slick-slide.slick-active.slick-center.slick-current {
    transform: scale(1.5);
} */