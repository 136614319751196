.blog-page {
  padding: 100px 0px;
}
/* blog card css starts here */
.blog-date-cta {
  display: flex;
  justify-content: space-between;
  color: #949090;
  font-size: 19px;
  font-weight: 500;
  margin-top: 23px;
}
.blog-card-wrapper h3.blog-name {
  color: #313f2b;
  font-weight: 400;
  line-height: 44px;
  font-size: 22px;
}
.blog-date-cta i.fa-calendar-o {
  margin-right: 10px;
}
.blog-card-wrapper p.blog-des {
  color: #707070;
  font-size: 16px;
}
.blog-date-cta i {
  margin-left: 10px;
}
.blog-card-wrapper h5.cta {
  text-transform: uppercase;
}
.blog-card-wrapper {
  margin-bottom: 50px;
}
/* blog card css ends here */
/* blog details page image */
.blog-detail {
  padding: 100px 0px;
}
.blog-comment {
  padding: 50px 0px 0px 0px;
}
.blog-comment h2 {
  color: #313f2b;
  font-size: 34px;
  margin-bottom: 30px;
}
.blog-comment input {
  border: 1px solid #b8b8b8;
  border-radius: 0px;
  padding: 22px 10px;
  margin-bottom: 15px;
}
.blog-comment textarea {
  border: 1px solid #b8b8b8;
  border-radius: 0px;
  padding: 22px 10px;
}
.blog-comment button.btn {
  background: #313f2b;
  color: #fff;
  padding: 14px 131px;
  margin-top: 23px;
  font-weight: 500;
  border: 2px solid #fff;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
}
.blog-comment button.btn:hover {
  outline: 2px solid #313f2b;
  border-radius: 0px;
}
.comment-heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-comments-wrapper {
  padding: 50px 0px;
}
.blog-comments-wrapper img.comment-img {
  width: 74%;
}
.user-comment-content span.user-name {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
}
.user-comment-content h4 {
  color: #525252;
  font-size: 15px;
  font-weight: 400;
}
.user-comment-content p {
  font-size: 15px;
}
.user-comment-content button.comment-reply {
  background: transparent;
  border: 0px;
  padding: 0px;
  text-decoration: underline;
  color: #313f2b;
}
.replyed-comment img.user-replyed-img {
  width: 82%;
}
.replyed-comment::before {
  content: "";
  width: 40px;
  height: 40px;
  background: whitesmoke;
  display: block;
  position: absolute;
  left: 6%;
  top: -2%;
  transform: rotate(44deg);
}

.replyed-comment {
  background: #f5f5f5;
  padding: 30px 25px;
  margin: 20px 0px;
  position: relative;
}
.replyed-comment .row:last-child {
  border: none;
}
.replyed-comment .row {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 19px;
}
.blog-detail .add-comment {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.blog-detail .add-comment button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 23px;
  background: #eeeeee;
  width: 374px;
  border: 0px;
  padding: 11px 20px;
}
.add-comment figure {
  margin: 0px;
}
.blog-detail button.rep span {
  color: #000000;
  font-size: 13px;
}
.comment-warp .single-comment {
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e3e3;
}
/* .single-comment:last-child{
  border-bottom: none;
} */
.blog-content-wrapper {
  padding-top: 30px;
}
.blog-content-wrapper h2 {
  margin-bottom: 20px;
  color: #313f2b;
  font-weight: 400;
}
/* responsive starts here */
@media (max-width: 480px) {
  .blog-comments-wrapper img.comment-img {
    width: unset;
  }
  .replyed-comment img.user-replyed-img {
    width: unset;
  }
  .blog-content-wrapper h2 {
    font-size: 35px;
  }
}
@media (max-width: 414px) {
  .blog-content-wrapper h2 {
    font-size: 30px;
  }
}
