.ApplyNow {
  padding: 100px 0px;
}
.applynow-form-wrapper h2 {
  color: #313f2b;
  margin-bottom: 30px;
}
.applynow-form-wrapper p {
  font-size: 15px;
}
.applynow-form-wrapper button.btn {
  background: #313f2b;
  padding: 12px 49px;
  color: #fff;
  font-size: 13px;
}
.applynow-form-wrapper .form-control {
  border: 1px solid #9b9b9b;
}
.applynow-form-wrapper label {
  font-size: 14px;
}
.applynow-form-wrapper .form-group {
  margin-bottom: 20px;
}
.applynow-form-wrapper .chick {
  padding-top: 30px;
  border-top: 1px solid #b7b7b7;
}
.applynow-form-wrapper .jXqwlN {
  fill: #313f2b;
}
.applynow-form-wrapper .uKgQD {
  border: solid 2px #313f2b;
}