.reserv-wrapper {
  padding: 100px 0px;
  padding-top: 200px;
}
.reserv-wrapper h3 {
  color: #373a36;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 30px;
}
.reserv-wrapper .form-wrapper p {
  color: #373a36;
  font-size: 14px;
  margin-bottom: 37px;
}
.special-offers-card-wrapper {
  margin-bottom: 40px;
  background: #f9f9f9;
  border-radius: 15px;
}
.special-offers-card-wrapper .special-content {
  padding: 5px 20px;
}
.special-offers-card-wrapper .special-content span {
  display: block;
}
.special-offers-card-wrapper .special-content br {
  display: none;
}
.reserv-wrapper .special-content h5.offer-title {
  font-size: 21px;
  font-weight: 500;
  color: #313131;
}
.reserv-wrapper .special-content p.des {
  color: #707070;
  font-size: 16px;
}
.reserv-wrapper .form-wrapper label {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}
.reserv-wrapper .form-wrapper .form-group {
  margin-top: 24px;
}
.reserv-wrapper .form-control {
  color: #000;
  padding: 9px 20px;
  height: 47px;
  border: 1px solid #9b9b9b;
}
.reserv-wrapper .form-control::placeholder {
  color: #c4c0c7;
}
.reserv-wrapper .form-wrapper h4.amount {
  background: #f9f9f9;
  display: inline-block;
  padding: 15px 73px 15px 20px;
  margin-top: 10px;
  color: #313f2b;
  font-size: 27px;
  font-weight: 500;
  border-radius: 10px;
}
.reserv-wrapper .form-wrapper label {
  display: block;
}
.reserv-wrapper
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  content: "\f00c";
  font-family: "FontAwesome" !important;
  margin-top: -5px;
  font-size: 16px;
  margin-left: 4px; 
  color: #bebebe;
  font-weight: 200;
}
.reserv-wrapper .form-wrapper label.custom-control-label {
  color: #bebebe;
}
.reserv-wrapper .custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
  background: #fff !important;
  border-radius: 5px;
  border-color: #bbbbbb !important;
  padding: 10px;
  margin-top: -3px;
}
.reserv-wrapper .form-wrapper .custom-control {
  display: inline-block;
}
.reserv-wrapper .form-btn {
  background: #313f2b;
  border-radius: 12px;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  padding: 16px 50px;
  margin-top: 30px;
}
.check-box-wrapper .col-lg-4 {
  border-right: 1px solid #b4b4b4;
}
