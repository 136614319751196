/* font family starts here */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/aileron");
@import url("https://fonts.cdnfonts.com/css/arial");
@import url("https://fonts.cdnfonts.com/css/futura-md-bt");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600&display=swap");

@font-face {
  font-family: "helvatica";
  src: url("../fonts/Helvetica-Bold.ttf");
}

@font-face {
  font-family: "brown-reg";
  src: url(../fonts/Brown-family/Brown-Regular.ttf);
}

@font-face {
  font-family: "brown-light";
  src: url(../fonts/Brown-family/Brown-Light.ttf);
}

@font-face {
  font-family: "neueRadial-A-Regular";
  src: url("../fonts/Fontspring-DEMO-neueradial-a-regular.otf");
}

@font-face {
  font-family: "neueRadial-A-Book";
  src: url("../fonts/Fontspring-DEMO-neueradial-b-regular.otf");
}

/* @font-face {
  font-family: "brown-reg";
  src: url("../fonts/Kommon Grotesk Com SemiBold.otf");
} */

/* font family starts here */
/* universal css */
a {
  color: unset;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
  color: unset;
}

.btn {
  background: #ffcc00;
  color: #253020;
  font-family: "Poppins";
  font-weight: 500;
  padding: 18px 52px;
  border: 1px solid #fff;
  transition: all 0.2s ease-in-out;
  font-size: 20px;
}

.btn:hover {
  outline: 2px solid #ffcc00;
  border-radius: 0px;
}

/* typography */
* {
  font-family: "brown-reg" !important;
}

h1 {
  font-size: 50px;
  line-height: 54px;
  font-weight: bold;
}

h2 {
  font-size: 36px;
  line-height: 40px;
  font-weight: 500;
}

h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
}

h4 {
  font-size: 18px;
  line-height: 25px;
  font-weight: bold;
}

h5 {
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
}

p {
  color: #636363;
  /* font-family: "Poppins"; */
  font-family: "brown-reg" !important;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400 !important;
}

@media (max-width: 1100px) {
  h2 {
    font-size: 35px;
    line-height: 40px;
  }
}

/* typography */
/* loading starts here */
#introduction {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 105;
  background-color: #000;
  cursor: pointer;
  overflow: hidden;
  top: 0;
  left: 0;
}

#introduction .image-container {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: relative;
}

@media (max-width: 768px) {
  #introduction .image-container {
    height: 80vh;
  }
}

#introduction .banner-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1600px;
}

#introduction .slide {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: #fff;
}

#introduction .slide-2 {
  height: 0;
}
.slide-1 {
  z-index: 999;
}

.image-container {
  background-color: #000;
}

#content {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: 600;
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
/* loading ends here */
/* Prelaoder */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #313f2b;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #ffcc00;
  border-top: 6px solid #313f2b;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* header starts here */
header#header .header-menu-wrapper .container-fluid {
  width: 95%;
}
.menu-item-wrapper ul#menu {
  display: flex;
  list-style: none;
  padding: 0px;
  justify-content: end;
  margin: 0px;
}
.menu-item-wrapper ul#menu li a {
  color: #2a2b29;
  font-family: "brown-reg";
  font-size: 24px;
  font-weight: 500;
}
#header a.btn {
  padding: 9px 26px;
}

@media (max-width: 1100px) {
  .menu-item-wrapper ul#menu li a {
    font-size: 13px;
  }
}

/* header ends here */
/* mobile header starts here */
header#header {
  position: fixed;
  width: 100%;
  z-index: 1;
  background: #fff;
  padding: 15px 0px;
  box-shadow: -1px 6px 5px 0px rgb(0 0 0 / 23%);
  -webkit-box-shadow: -1px 6px 5px 0px rgb(0 0 0 / 23%);
  -moz-box-shadow: -1px 6px 5px 0px rgba(0, 0, 0, 0.23);
  z-index: 9;
}

header#header img {
  height: 52px;
}

.mobile-header .cancel svg {
  margin-top: -18px;
}

#header .canvas-icon i {
  font-size: 23px;
  display: none;
}

header .mobile-header {
  width: 30%;
  height: 100%;
  position: fixed;
  background: #516a44;
  top: 0;
  z-index: 1;
  right: 0;
  padding-top: 30px;
  transition: 0.7s;
  transform: translateX(100%);
}

header .mobile-header.show {
  transform: translateX(0%);
}

header .mobile-header ul.mobile-nav {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

header .mobile-header .cancel {
  background: #fff;
  width: 35px;
  text-align: center;
  height: 35px;
  line-height: 55px;
  margin: 0px 20px 20px auto;
}

header .mobile-header ul.mobile-nav li.nav-item {
  padding: 10px 0;
  border: 0;
  border-bottom: 1px solid #dddddd4a;
}

header .mobile-header ul.mobile-nav li.nav-item a {
  color: #fff;
  font-size: 14px;
  padding: 0 20px;
}

/* responsive */
@media (max-width: 1024px) {
  #header .canvas-icon i {
    display: block;
    text-align: right;
  }

  .menu-item-wrapper {
    display: none;
  }
}

@media (max-width: 800px) {
  header .mobile-header {
    width: 52%;
    z-index: 3;
  }
}

@media (max-width: 360px) {
  header .mobile-header {
    width: 50%;
  }
}

/* responsive */
/* mobile header ends here */
/* banner starts here */
.banner {
  overflow-x: hidden;
  background-color: #fff;
}

.banner-slider-item {
  position: relative;
}
.banner-content-wrapper {
  position: absolute;
  top: 27%;
  background: #fff;
  padding: 31px 40px;
  border-radius: 8px;
  width: 55%;
}
.banner h1 {
  color: #516a44;
  font-family: "brown-reg";
  font-size: 60px;
  line-height: 1.1;
}
.banner h1 span {
  color: #516a44;
  font-family: "brown-reg";
}

.banner-content-wrapper p {
  /* font-size: 12px; */
  line-height: 25px;
  color: #000;
}

.grid-img {
  display: flex;
}
/* .grid-img figure::after { */
/* content: "";
  position: absolute;
  width: 97%;
  height: 100%;
  display: block;
  background: #e6b03963;
  top: 0px;
  border-radius: 10px;
  transform: translateY(-100%);
  transition: all 0.4s ease-in-out;
}
.grid-img figure {
  position: relative;
  overflow: hidden;
}
.grid-img:hover figure::after {
  transform: translateY(0%);
}
.grid-img {
  transition: all 0.5s ease-in-out;
} */
.short-imgs {
  padding-right: 10px;
}
.banner-content-wrapper p {
  font-size: 18px;
  line-height: 25px;
  font-family: "brown-reg" !important;
  color: #2a2b29;
  margin: 20px 0px;
  margin-bottom: 24px;
}
.banner-slider-item form {
  box-shadow: 0px 0px 4px 3px #d5d5d5;
  border-radius: 9px;
  padding: 9px;
}

.banner-form-wrapper .form-group:nth-child(1) {
  border-right: 1px solid #707070;
}

.banner-form-wrapper label {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  color: #a7a7a7;
  font-size: 14px;
}

.banner .slick-dots {
  bottom: 30px;
}

.banner .slick-dots li button:before {
  color: #fff !important;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  border: 1px solid !important;
}

.banner .slick-dotted.slick-slider {
  margin-bottom: 0px;
}

.banner-form-wrapper label span {
  color: #2e2b29;
  font-size: 19px;
  font-weight: 500;
}

.banner-form-wrapper input {
  padding: 17px;
  border: 0px;
}
.banner-img-wrapper img {
  width: 100% !important;
  height: 110vh;
}
.banner-slider-item .container {
  position: initial;
}

.banner button.slick-next.slick-arrow {
  z-index: 1;
  right: 46%;
  bottom: 3% !important;
  top: unset;
}

.banner button.slick-prev.slick-arrow {
  z-index: 1;
  left: 46%;
  bottom: 3% !important;
  top: unset;
}

.banner .slick-prev:before {
  content: "\f104" !important;
  font-family: "FontAwesome";
  font-size: 28px;
}

.banner .slick-next:before {
  content: "\f105" !important;
  font-family: "FontAwesome";
  font-size: 28px;
}

.banner .btn {
  background: #ffcc00;
  color: #253020;
  font-family: "Poppins";
  font-weight: 500;
  padding: 18px 27px;
  border: 1px solid #fff;
  transition: all 0.2s ease-in-out;
  font-size: 18px;
  border-radius: 8px;
}

.banner .form-inline .form-control {
  width: 168px;
}
/* .banner-form-wrapper select.form-control {
  border: 0px;
  margin-right: 11px;
  font-family: "neueRadial-A-Book";
  color: #2E2B29;
  font-size: 19px;
  height: 66px;
  background-image: url("../img/Icon ionic-ios-arrow-down.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
  -webkit-appearance: none;
} */
.banner-form-wrapper select.form-control {
  border: 0px;
  margin-right: 11px;
  font-family: "neueRadial-A-Book";
  color: #2e2b29;
  font-size: 23px;
  height: 66px;
  background-image: url("../img/Icon ionic-ios-arrow-down.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
  -webkit-appearance: none;
}
/* .banner-form-wrapper select.form-control {
  border: 0px;
  margin-right: 11px;
  font-family: "neueRadial-A-Book";
  color: #2e2b29;
  font-size: 19px;
  height: 66px;
  background-image: url("../img/Icon ionic-ios-arrow-down.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
  -webkit-appearance: none;
} */
/* responsive */
@media (max-width: 1440px) {
  .banner .slick-dots {
    bottom: 27px;
  }
  .banner-content-wrapper p br {
    display: none;
  }
  .banner button.slick-prev.slick-arrow {
    left: 45%;
  }

  .banner button.slick-next.slick-arrow {
    right: 45%;
  }

  /* .banner-content-wrapper {
    left: 12.5%;
  } */
  .banner .btn {
    background: #ffcc00;
    color: #253020;
    font-family: "Poppins";
    font-weight: 500;
    padding: 18px 37px;
    border: 1px solid #fff;
    transition: all 0.2s ease-in-out;
    font-size: 20px;
  }

  .banner h1 {
    font-size: 50px;
    line-height: 55px;
    font-weight: bold;
  }
}

@media (max-width: 1366px) {
  .banner-content-wrapper {
    left: 10%;
  }
}

@media (max-width: 1280px) {
  .banner-content-wrapper {
    left: 7%;
  }

  .banner .slick-dots {
    bottom: 30px;
  }
}

@media (max-width: 1100px) {
  .banner-content-wrapper {
    left: 4%;
    top: 15%;
    padding: 40px;
  }

  .banner button.slick-prev.slick-arrow {
    left: 44%;
    margin-bottom: 3px;
  }

  .banner button.slick-next.slick-arrow {
    right: 44%;
    margin-bottom: 3px;
  }
}

@media (max-width: 991px) {
  .banner-content-wrapper p br {
    display: none;
  }

  .banner h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .banner-content-wrapper {
    left: 0%;
    top: 15%;
    padding: 27px;
    margin: 0px 10px;
  }

  .banner .btn {
    padding: 11px 32px;
  }
}

@media (max-width: 800px) {
  .banner h1 br {
    display: none;
  }

  .banner-form-wrapper label span {
    font-size: 16px;
  }

  .banner .btn {
    padding: 11px 32px;
    width: 100%;
    margin: 10px 0px;
  }

  .banner .slick-slide img {
    height: 500px;
  }

  .banner button.slick-prev.slick-arrow {
    left: 41%;
  }

  .banner button.slick-next.slick-arrow {
    right: 41%;
    margin-bottom: 3px;
  }
}

@media (max-width: 480px) {
  .banner .slick-slide img {
    height: 600px;
    object-fit: cover;
  }

  .banner-content-wrapper form.form-inline .form-group {
    width: 45%;
    text-align: center !important;
    padding-left: 8px !important;
  }

  .banner-content-wrapper p {
    font-size: 15px;
    line-height: 26px;
  }

  .banner-content-wrapper {
    top: 19%;
    padding: 16px;
  }

  .banner button.slick-next.slick-arrow {
    right: 37%;
    margin-bottom: 5px;
  }

  .banner button.slick-prev.slick-arrow {
    left: 37%;
    bottom: 3.5% !important;
  }
}

@media (max-width: 414px) {
  .banner button.slick-prev.slick-arrow {
    left: 35%;
  }
}

@media (max-width: 375px) {
  .banner-content-wrapper form.form-inline .form-group {
    margin: 0px;
  }

  .banner .btn {
    margin-bottom: 0px;
  }

  .banner-content-wrapper p {
    margin-bottom: 5px;
  }

  .banner button.slick-next.slick-arrow {
    right: 33%;
  }

  .banner button.slick-prev.slick-arrow {
    left: 33%;
  }
}

/* banner ends here */
/* page header css starts here */
.PageHeader {
  padding: 100px 0px;
  padding-top: 200px !important;
  background: url("../img/pagebanner.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.PageHeader h1 {
  color: #fff;
  font-size: 62px;
  line-height: 72px;
}

.PageHeader span.bread-cumbs {
  color: #fff;
}

.page-heading-wrapper span.bread-cumbs span {
  text-transform: capitalize;
}

@media (max-width: 480px) {
  .PageHeader {
    background-position: center;
  }

  .PageHeader h1 {
    font-size: 48px;
  }
}

/* page header css ends here */
/* icon section starts here */
.icon {
  padding: 40px 0px;
}

.icon-content-wrapper p {
  color: #636363;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 480px) {
  .icon .icon-wrapper {
    padding: 40px 0px;
  }
}

/* icon section ends here */
/* appartment section starts here */
.furnished {
  padding: 100px 0px;
  background-color: #fff;
}

.furnished h6 {
  color: #707070;
  font-size: 16px;
  font-weight: 500;
}
.furnished h2 {
  color: #2a2b29;
  font-size: 50px;
}
.furnished p {
  color: #333433;
  font-size: 18px;
}

.future .col-lg-4 .future-image-box img {
  height: 231px;
  object-fit: cover;
  border-radius: 10px;
}
.furnished .slick-dots li button:before {
  font-size: 12px !important;
}
/* .future-image-box figure::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background: #e6b03963;
  bottom: 0px;
  border-radius: 10px;
  transform: translateY(-100%);
  transition: all 0.4s ease-in-out;
} */

.future-image-box figure {
  position: relative;
  overflow: hidden;
}
.future-image-box:hover figure::after {
  transform: translateY(0%);
}

.future-image-box {
  transition: all 0.5s ease-in-out;
}
@media (max-width: 991px) {
  .future .col-lg-4 .future-image-box {
    display: inline-block;
    width: 49%;
  }
}

@media (max-width: 480px) {
  .furnished button.slick-arrow {
    display: none !important;
  }

  .furnished .card-wrapper button.slick-arrow {
    display: block !important;
  }

  .future p br {
    display: none;
  }

  .future .col-lg-4 .future-image-box {
    display: block;
    width: 100%;
  }

  .future .col-lg-4 .future-image-box img {
    width: 100%;
  }
}

/* appartment section ends here */
/* future of living */
.future {
  background: #f9f9f9;
  padding: 60px 0px 100px 0px;
  margin-top: -25px;
}
.future h2 {
  color: #2a2b29;
  margin-bottom: 10px;
  font-size: 50px;
  font-weight: 400;
}
.future p {
  color: #2a2b29;
  font-size: 18px;
}
.furnished p.description {
  color: #707070;
  font-family: "Futura Bk BT";
  font-size: 14px;
}
.furnished ul.specification li {
  font-family: "Futura Md BT";
  font-size: 11px;
  font-weight: 500;
  color: #272727;
}
.furnished p.description {
  font-family: "brown-reg" !important;
  color: #707070;
  font-size: 14px;
}
.furnished p.price span {
  font-size: 15px;
  font-family: "brown-reg";
}
.card-wrapper h4.appartment-name {
  color: #2a2b29;
  font-family: "brown-reg" !important;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 12px;
  text-shadow: 0 0 #272727;
}
.card-img button.slick-prev {
  left: 3%;
  z-index: 1;
}
.card-img {
  position: relative;
  margin-bottom: 10px !important;
}
.card-img .slick-dots li.slick-active button:before {
  border: 0px !important;
}
.card-img button.slick-next {
  right: 5%;
  z-index: 1;
}
.card-wrapper .slick-dots {
  bottom: 10px;
}
.furnished .card-content-wrapper {
  padding: 12px;
  padding-bottom: 0px !important;
}
.furnished .card-wrapper {
  padding-bottom: 8px;
}
.furnished .card-wrapper .card-img img {
  height: 280px;
  object-fit: cover;
  border-radius: 5px;
}
.card-img .slick-dots li.slick-active button:before {
  opacity: 1;
  color: white;
}

.card-img .slick-dots li button:before {
  font-family: "slick";
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 13px;
  height: 13px;
  content: "•";
  text-align: center;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px !important;
}
.card-wrapper {
  background: #f9f9f9;
  padding: 0px 0px 20px 0px;
  box-shadow: 0px 0px 36px 9px #efefef !important;
  border-radius: 20px;
}
.furnished ul.specification {
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.furnished .card-img {
  margin-bottom: 0px !important;
}
.furnished .container {
  max-width: 1300px;
}
.furnished .card-img .slick-dots li.slick-active button:before {
  color: #fff !important;
}
.furnished .slick-track {
  padding: 50px 0px;
}

.furnished .card-img .slick-track {
  padding: 0px;
}

.furnished ul.specification li {
  display: flex;
  font-size: 14px;
  align-items: center;
  color: #2a2b29;
  font-weight: 400;
}
.furnished p.price span.bold {
  font-weight: 700;
  color: #2a2a2a;
}
.furnished p.price {
  color: #2A2B29;
  font-family: "brown-reg" !important;
  font-size: 18px;
  margin-bottom: 10px;
}
.furnished ul.specification li img {
  margin-right: 10px;
}

.slick-dots li.slick-active button:before {
  font-size: 12px;
  border: 1px solid #516a44 !important;
  border-radius: 50%;
  color: #516a44 !important;
  opacity: 1 !important;
}

.slick-dots li button:before {
  font-size: 13px;
  color: #d9d9d9;
  opacity: 1;
}

.furnished .card-wrapper .slick-prev::before {
  content: "\f104";
  font-family: "FontAwesome";
  font-size: 27px;
  background: #fff;
  padding: 4px 12px;
  border-radius: 10px;
  color: #313f2b;
  opacity: 0.7;
  font-weight: 700;
}

.furnished .card-wrapper .slick-next::before {
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 27px;
  background: #fff;
  padding: 4px 12px;
  border-radius: 10px;
  color: #313f2b;
  opacity: 0.7;
  font-weight: 700;
}

@media (max-width: 1100px) {
  .future .col-lg-4 .future-image-box img {
    height: 193px;
  }

  .future {
    padding: 60px 0px 50px 0px;
  }

  .furnished ul.specification li {
    font-size: 9px;
  }
}

/* future of living */
/* best rates section starts here */
.best-rates {
  background: #002441;
  padding: 80px 0px;
}

.best-rates h2 {
  color: #fff;
  margin-bottom: 50px;
}

.best-rates .rates-wrapper img {
  width: 100%;
}

.best-rates h5 {
  color: #fff;
  font-size: 13px;
}

.best-rates .rates-wrapper {
  margin-bottom: 20px;
}
.blog_box .content-box {
  padding-top: 15px;
}
.blog_box .content-box h5 {
  padding-bottom: 5px;
}
.blog_box .content-box p {
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 21px;
  color: #2A2B29;
}
@media (max-width: 991px) {
  .best-rates h2 br {
    display: none;
  }
}

/* best rates section ends here */
/* reinventing section starts here */
.reinventing {
  padding: 50px 0px 80px;
  background-color: #fff;
}
.reinventing .blog_box .imgBox img {
  height: 190px;
  object-fit: cover;
}
.reinventing h2 {
  color: #2A2B29;
  font-size: 50px;
}
.reinvention-card-wrapper h3 {
  color: #093c65;
  font-weight: 600;
  font-size: 21px;
}
.reinventing h5 {
  font-family: "brown-reg";
  color: #2A2B29;
  font-weight: 500;
  font-size: 24px;
}
.reinvention-card-wrapper p {
  font-size: 14px;
}

@media (max-width: 991px) {
  .reinventing .reinvention-card-img img {
    width: 100%;
  }
}

/* reinventing section ends here */
/* testimonials section starts here */
.testimonials {
  background: #f9f9f9;
  padding: 70px 0px;
}
.testimonials h2 {
  color: #2a2b29;
  font-size: 50px;
  color: #2A2B29;
  padding-bottom: 20px;
}
.partner-wrapper img {
  transform: scale(0.9);
}

.partner-wrapper {
  background: #516a44;
  border-radius: 7px 0px 0px 7px;
}

.partner-wrapper {
  padding: 30px;
}
.partner-wrapper h4 {
  color: #fff;
  font-family: "brown-reg";
  font-size: 30px;
  margin-top: 31px;
}
.partner-wrapper ul {
  padding: 0px;
  list-style: none;
}

.partner-wrapper ul li {
  display: inline-block;
  margin: 20px 6px;
}

.partner-wrapper {
  text-align: center;
}

.client-name-wrapper {
  display: flex;
  align-items: center;
}

.client-name-wrapper .client-img {
  margin-right: 20px;
}

.quotelower {
  border-bottom: 1px solid #707070;
  padding-bottom: 24px;
  display: flex;
  justify-content: end;
}
.testimonials-wrapper {
  background: #ececec;
  padding: 37px;
  padding-bottom: 19px;
  border-radius: 0px 7px 7px 0px;
}
.client-name-wrapper {
  margin-top: 20px;
}
.client-details-wrapper h4 {
  font-family: "Futura Md BT";
  font-size: 24px;
  font-weight: 500;
  color: #2A2B29;
}
.client-details-wrapper p {
  font-size: 15px;
  color: #2A2B29;
  margin-bottom: 0px;
  font-weight: 500 !important;
}
.testimonails-quote p {
  margin-top: 1rem;
  color: #2A2B29;
  font-size: 15px;
  text-shadow: 0 0 #2A2B29;
}
.testimonials button.slick-next.slick-arrow {
  right: 8%;
  position: absolute;
  bottom: 0px;
  top: 82%;
}

.testimonials button.slick-prev.slick-arrow {
  right: 17%;
  position: absolute;
  bottom: 0px;
  top: 82%;
  left: unset;
  z-index: 1;
}

.testimonials .slick-next:before {
  content: url(../img/long-right-arrow.svg) !important;
  background: #e6b039;
  opacity: 1;
  font-size: 6px;
  padding: 18px 17px;
  border-radius: 0px 23px 23px 0px;
}

.testimonials .slick-prev:before {
  content: url(../img/long-left-arrow.svg) !important;
  background: #e6b039;
  opacity: 1;
  font-size: 6px;
  padding: 18px 17px;
  border-radius: 23px 0px 0px 23px;
}

@media (max-width: 1100px) {
  .client-details-wrapper h4 {
    font-size: 18px;
  }

  .client-details-wrapper p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .testimonials button.slick-next.slick-arrow {
    right: 10%;
    top: 77%;
  }

  .testimonials button.slick-prev.slick-arrow {
    right: 23%;
    top: 77%;
  }

  .partner-wrapper ul li {
    width: 42%;
  }
}

@media (max-width: 414px) {
  .testimonials {
    padding: 30px 0px;
  }

  .testimonials h2 {
    font-size: 30px;
    line-height: 35px;
  }
}

@media (max-width: 375px) {
  .testimonials h2 br {
    display: none;
  }

  .testimonials .slick-prev:before {
    padding: 11px 12px;
  }

  .testimonials button.slick-next.slick-arrow {
    right: 11%;
    top: 95%;
  }

  .testimonials button.slick-prev.slick-arrow {
    right: 28%;
    top: 95%;
  }

  .testimonials .slick-next:before {
    padding: 11px 12px;
  }
}

/* testimonials section ends here */
.destination {
  background-color: #e6b039;
  width: 50%;
  height: 55px;
  box-shadow: 0px 3px 1px -2px rgb(230 176 57), 0px 2px 2px 0px rgb(230 176 57),
    0px 1px 5px 0px rgb(230 176 57);
  color: #000;
}

/* footer starts here */
.footer {
  background: #2a2b29;
  padding: 100px 0px;
}
footer h3 {
  color: #f9f9f9;
  font-weight: 400;
  margin-bottom: 30px;
  font-size: 24px;
}
.footer-widget-4 {
  text-align: end;
}
ul#social {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding: 0px;
}

ul#social li {
  margin: 0px 20px 0px 0px;
}

footer ul#quick-links {
  padding: 0px;
  list-style: none;
  /* column-count: 2; */
}

footer ul#quick-links li a {
  color: #F9F9F9;
  line-height: 32px;
  font-family: "brown-light" !important;
  font-size: 16px;
  font-weight: 500;
  text-shadow: 0 0 #fff;
}

footer .contact-info {
  padding: 0px;
  list-style: none;
}
footer .contact-info li {
  display: flex;
  align-items: baseline;
  color: #f9f9f9 !important;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: "brown-light" !important;
  justify-content: end;
  text-shadow: 0 0 #fff;
}
.footer .footer-widget-3 h3 {
  text-align: end;
}
.footer-widget-2 {
  margin-left: 36px;
}
footer .contact-info li i {
  margin-right: 20px;
}

footer .contact-info {
  width: 100%;
}

.ter-wrapper span {
  font-size: 13px;
  color: #f9f9f9;
  font-family: "poppins";
}

footer p {
  font-size: 16px;
  color: #f9f9f9;
}

footer ul#contact {
  list-style: none;
  padding: 0px;
}
ul#contact li a {
  color: #f9f9f9;
  line-height: 32px;
  font-family: "brown-light" !important;
  font-weight: 400;
  font-size: 16px;
  text-shadow: 0 0 #fff;
}
footer a.btn {
  background: #ffcc00;
  color: #253020;
  font-family: "Poppins";
  font-weight: 500;
  padding: 14px 47px;
  border: 1px solid #313f2b;
  transition: all 0.2s ease-in-out;
  margin-top: 20px;
}

footer a.btn:hover {
  outline: 2px solid #ffcc00;
  border-radius: 0px;
}
footer.footer .ter-wrapper a {
  font-family: "brown-light" !important;
  font-size: 14px;
}
footer ul#contact i {
  color: #fff;
  margin-right: 10px;
}
footer.footer i:before {
  font-family: 'FontAwesome' !important;
}
#copyright {
  background: #2a2b29;
}

#copyright .container {
  padding-top: 20px;
  border-top: 1px solid #9fa49c;
}
#copyright p {
  color: #9fa49c !important;
  font-family: "brown-light" !important;
}
@media (max-width: 1100px) {
  ul#contact li a {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  footer .footer-widget-2 {
    margin-left: 0px;
  }
}

/* footer ends here */
