.job-sec .time-and-price {
  display: flex;
  justify-content: space-between;
}
.job-sec h4.date {
  color: #636060;
  font-size: 13px;
  font-weight: 400;
  text-align: end;
}
.time-and-price h5 {
  color: #636060;
  font-size: 18px;
  font-weight: 400;
}
.time-and-price h5 i {
  color: #313f2b;
  margin-right: 10px;
  font-size: 20px;
}
.job-details .content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job-details {
  padding: 100px 0px;
}
.job-details .time-and-price {
  display: flex;
}
.job-details .time-and-price h5 {
  margin-right: 20px;
}
.job-details .cont-wrap h5 {
  text-align: end;
  color: #636060;
  font-size: 13px;
  font-weight: 300;
}
.job-details .time-and-price h5 {
  color: #636060;
  font-size: 16px;
  font-weight: 400;
}
.job-details h2 {
  color: #313f2b;
  font-size: 36px;
  font-weight: 400;
}
.des-row h3 {
  color: #acacac;
  font-size: 25px;
  font-weight: 500;
}
.des-row p {
  color: #363636;
  margin-top: 10px;
}
.des-row .content-wrap {
  margin-top: 30px;
}
.des-row h3 {
  color: #acacac;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 25px;
}
.des-row .btn {
  padding: 10px 32px;
  background: #313f2b;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  border: 1px solid #fff;
}

.des-row .btn:hover {
  outline: 2px solid #313f2b;
}
@media (max-width: 480px) {
  .job-details .cont-wrap h5 {
    text-align: left;
  }
  .job-details .content-wrapper {
    align-items: baseline;
    flex-direction: column;
  }
}
