/* about section css starts here */
.pageAbout {
    padding: 100px 0px;
    padding-bottom: 50px;
}
.pageAbout h3 {
    color: #8F8F8F;
    font-weight: 300;
    font-size: 22px;
}
.pageAbout h2 {
    color: #313F2B;
    font-weight: 500;
    font-size: 30px;
}
.pageAbout p {
    font-size: 14px;
}
/* about section css ends here */