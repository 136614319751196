.profile {
  padding: 100px 0px;
}
.profile div#v-pills-tab a {
  padding: 5px 31px;
  margin: 22px 0px;
  font-size: 22px;
  color: #888888;
  font-weight: 500;
}
.profile div#v-pills-tab {
  margin-top: 25px;
  background: #f9f9f9;
  border-radius: 13px;
}
.profile div#v-pills-tab .nav-link.active {
  background: #f9f9f9;
  color: #516a44;
  font-size: 22px;
  font-weight: 500;
  border-left: 3px solid;
  border-radius: 0px;
}
.profile h3.profile-heading {
  color: #516a44;
  font-size: 30px;
}
.profile-wrap {
  display: flex;
  align-items: center;
}
.profile-wrap .img-wrap img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}
.profile-wrap .img-wrap i {
  background: #516a44;
  font-size: 26px;
  border-radius: 22px;
  height: 35px;
  width: 36px;
  text-align: center;
  line-height: 38px;
  color: #fff;
  position: absolute;
  bottom: 13px;
  left: 63%;
}
.profile-wrap .img-wrap {
  position: relative;
}
.profile-wrap .profile-name-wrp {
  margin-left: 20px;
}
.profile-wrap .profile-name-wrp h3 {
  color: #516a44;
  font-size: 28px;
  font-weight: 600;
}
.profile-wrap .profile-name-wrp h4 {
  font-size: 22px;
  color: #888888;
  font-weight: 400;
}

.profile-and-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-and-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-and-edit button.edit {
  background: transparent;
  border: 1px solid #707070;
  padding: 10px 17px;
  border-radius: 4px;
  color: #707070;
  font-size: 15px;
  font-weight: 500;
}
.my-pro-wrapper .profile-form-wrapper-edit {
  margin-top: 50px;
}
.my-pro-wrapper .profile-form-wrapper-edit input {
  background: transparent;
  border: 0px;
  border-bottom: 1px solid #c4c0c7;
  border-radius: 0px;
  font-weight: 400;
  font-size: 20px;
  color: #000;
}
.my-pro-wrapper .profile-form-wrapper-edit input::placeholder {
  color: #c4c0c7;
}
.profile-form-wrapper-edit label {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}
.profile-form-wrapper-edit .form-group {
  margin-top: 30px;
}

.profile-form-wrapper-edit label.form-check-label {
  color: #bebebe;
  font-size: 17px;
  margin-left: 9px;
}

.profile-form-wrapper-edit .custom-checkbox .custom-control-label::before {
  border-radius: 5px;
  border: 2px solid #bebebe;
  background: #f9f9f9;
  padding: 8px;
  margin-left: -2px;
  outline: none;
}
.profile-form-wrapper-edit .custom-checkbox .custom-control-label:focus {
  outline: none;
}
.profile-form-wrapper-edit
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  content: "\f00c";
  background-image: none;
  background-color: transparent !important;
  font-family: "FontAwesome";
  margin-top: 0px;
  font-size: 14px;
  margin-left: 1px;
  outline: none;
}

.profile-form-wrapper-edit .hyHChn {
  stroke: #bebebe !important;
}
.profile-form-wrapper-edit .cmpJwL {
  border: solid 2px #bebebe !important;
}
.profile-form-wrapper-edit .form-check span {
  color: #bebebe;
}
.profile-form-wrapper-save label {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}
.profile-form-wrapper-save input {
  border: 1px solid #9b9b9b;
  padding: 11px 15px;
}
.zip {
  display: none;
}
.rever-wrap .title-and-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rever-wrap .title-and-price h4 {
  color: #313131;
  font-weight: 500;
  font-size: 23px;
}
.rever-wrap p.des {
  color: #707070;
  font-size: 17px;
}
.further-details {
  display: flex;
  align-items: center;
}
.further-details .date-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.further-details p {
  margin-bottom: 0px;
}

.rever-wrap .further-details button.reser-btn {
  background: #badfc5;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  color: #313131;
  border-radius: 6px;
}

.date-details p {
  padding-left: 10px;
  border-left: 1px solid;
}
.further-details p.night {
  padding-right: 10px;
}

.booking-details .booking-details-wrapper {
  margin-top: 30px;
}
.booking-details-wrapper table {
  width: 100%;
  margin-top: 30px;
}

.booking-details-wrapper table tr th {
  padding: 10px;
  font-size: 16px;
  color: #000000;
  font-weight: 500;
}

.booking-details-wrapper table tr td {
  padding: 10px;
  border-right: 1px solid #d4d4d4;
  font-size: 16px;
  color: #c4c0c7;
}

.booking-details-wrapper table tr td:last-child {
  border: none;
}

.booking-details-wrapper table tr th {
  border-right: 1px solid #d4d4d4;
}

.booking-details-wrapper table tr th:last-child {
  border: 0px;
}
.booking-details-wrapper table tr td .confirm {
  color: #c1e2ca;
}
.booking-details .content-wrapper h3 {
  font-size: 23px;
  color: #313131;
  font-weight: 500;
}
.payment-form-wrapper input[type="radio"] {
  display: none;
}
.addcartwrapper input[type="text"] {
  height: 51px;
}

.addcartwrapper input[type="radio"]:checked + label img {
  border: 1px solid #f35f63;
}
.addcartwrapper button.btn-card {
  background: #f35f63;
  border: 0px;
  color: #fff;
  padding: 14px 51px;
  font-weight: bold;
  font-size: 13px;
  font-family: "Spartan";
}
.addcartwrapper .card-info {
  display: flex;
  align-items: center;
}
.editss {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 40px;
}
.addcartwrapper label.container {
  padding: 0;
}
.addcartwrapper label.container span.checkmark .card-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #707070;
  padding: 10px 20px;
  position: relative;
}
.addcartwrapper label.container input:checked ~ span.checkmark .card-options {
  box-shadow: 0px 0px 0px 2px #f35f63;
  border-color: #fff;
}
.addcartwrapper label.container span.checkmark .card-options .visa-card {
  display: flex;
  align-items: center;
}
.addcartwrapper
  label.container
  span.checkmark
  .card-options
  .visa-card
  .form-group
  label {
  font-size: 8px;
  color: #000000;
}
.addcartwrapper
  label.container
  span.checkmark
  .card-options
  .visa-card
  .form-group
  .form-control {
  border: 0;
  font-size: 18px;
  color: #343a40;
  height: 20px;
  padding: 0;
}
.addcartwrapper
  label.container
  span.checkmark
  .card-options
  .visa-card
  .img-box {
  margin-right: 20px;
}
.addcartwrapperlabel.container span.checkmark .card-options .action-btns {
  text-align: right;
}
.addcartwrapper
  label.container
  span.checkmark
  .card-options
  .action-btns
  button.delete {
  background: transparent;
  border: 0;
  padding-bottom: 10px;
}
.addcartwrapper
  label.container
  span.checkmark
  .card-options
  .action-btns
  button.delete
  i {
  color: #000;
  font-size: 20px;
  padding-bottom: 16px;
}
.addcartwrapper
  label.container
  span.checkmark
  .card-options
  .action-btns
  .use-default
  label {
  font-size: 15px;
  color: #1f2c35;
  padding-right: 60px;
}
.addcartwrapper
  label.container
  span.checkmark
  .card-options
  .action-btns
  .use-default
  span.check-box {
  width: 18px;
  height: 18px;
  background: #fff;
  position: absolute;
  right: 24px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 0px 1px #f35f63;
}
.addcartwrapper
  label.container
  input:checked
  ~ span.checkmark
  .card-options
  .action-btns
  .use-default
  span.check-box {
  background: #f35f63;
}

.addcartwrapper .form-check-inline .form-check-input {
  display: none;
}
.addcartwrapper button {
  background: transparent;
  border: 1px solid #707070;
  padding: 10px 17px;
  border-radius: 4px;
  color: #707070;
  font-size: 15px;
  font-weight: 500;
  text-align: end;
}

.addcartwrapper input {
  background: transparent !important;
  border: 0px;
  border-bottom: 1px solid #c4c0c7;
  border-radius: 0px;
  font-weight: 400;
  font-size: 20px;
  color: #000;
}
.addcartwrapper input {
  color: #c4c0c7 !important;
}
.addcartwrapper label {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}
.addcartwrapper .form-group {
  margin-top: 30px;
}
.addcartwrapper .cart-form-wrapper h3 {
  color: #313131;
  font-weight: 500 !important;
  margin-top: 50px;
}

.add-cart-form-wrapper input {
  border: 1px solid #9b9b9b;
  padding: 11px 15px;
  border-radius: 5px;
}
.add-cart-form-wrapper label {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.balance-wrapper table {
  width: 100%;
}

.balance-wrapper table tr td {
  padding: 15px 18px;
  background: #f9f9f9;
  color: #c4c0c7;
  font-weight: 400;
}

.balance-wrapper table tr th {
  padding: 10px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.addcartwrapper .balance-wrapper h3 {
  color: #313131;
  font-weight: 500;
  font-size: 23px;
}
.addcartwrapper .balance-wrapper {
  margin-top: 50px;
}

.addcartwrapper.pagination {
  justify-content: end;
}
.addcartwrapper .arrow a.page-link {
  background: #fff;
  border: 0px;
}
.addcartwrapper .arrow a.page-link i {
  color: #313f2b;
  font-size: 22px;
}
.addcartwrapper ul.pagination li.page-item a.page-link {
  padding: 3px 8px;
  margin: 0px 2px;
  border-radius: 5px;
  background: #dedede;
  color: #fff;
}
.addcartwrapper ul.pagination li.page-item.active a.page-link {
  border: 1px solid #31402c !important;
  background: #fff !important;
  color: #313f2b;
  font-weight: 500;
}
.addcartwrapper .arrow a.page-link {
  background: transparent !important;
}

.reserve-pop-up-0 .rented-appartment-sec {
  padding: 30px 0px;
  padding-bottom: 30px;
}
.reserve-pop-up-0 .rented-appartment-sec .slick-dots {
  bottom: 10px;
}
.reserve-pop-up-0 .rented-appartment-sec .slick-prev::before {
  content: "\f104";
  font-family: "FontAwesome";
  font-size: 27px;
  background: #fff;
  padding: 4px 12px;
  border-radius: 10px;
  color: #313f2b;
  opacity: 0.7;
  font-weight: 700;
}
.reserve-pop-up-0 .rented-appartment-sec .slick-next::before {
  content: "\f104";
  font-family: "FontAwesome";
  font-size: 27px;
  background: #fff;
  padding: 4px 12px;
  border-radius: 10px;
  color: #313f2b;
  opacity: 0.7;
  font-weight: 700;
}
.reserve-pop-up-0 .rented-appartment-sec .button-wrapper button.btn {
  padding: 13px 31px;
  border-radius: 7px;
  width: 49%;
  color: #454d40;
  font-size: 20px;
  font-weight: 500;
  background: #f9f9f9;
}
.reserve-pop-up-0 .rented-appartment-sec .button-wrapper {
  display: flex;
  justify-content: space-between;
}
.reserve-pop-up-0 .rented-appartment-sec .property-name-and-night {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reserve-pop-up-0 .rented-appartment-sec .property-name-and-night h5 {
  font-size: 20px;
  color: #3b4b35;
  font-weight: 400;
}
.reserve-pop-up-0 .rented-appartment-sec .property-name-and-night h3 {
  color: #373a36;
  font-size: 30px;
  font-weight: 600;
}
.reserve-pop-up-0 .rented-appartment-sec .content-wrapper span {
  color: #707070;
  font-size: 20px;
}
.reserve-pop-up-0 .rented-appartment-sec .content-wrapper .price {
  color: #707070;
  font-size: 20px;
  margin-top: 10px;
}
.reserve-pop-up-0 .rented-appartment-sec .decription-wrapper h4 {
  color: #454d40;
  font-weight: 500;
  font-size: 20px;
}
.reserve-pop-up-0 .rented-appartment-sec .decription-wrapper p {
  color: #373a36;
  font-size: 14px;
}
.reserve-pop-up-0 .rented-appartment-sec .decription-wrapper .btn {
  background: #3b4b35;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  width: 76%;
  border-radius: 8px;
  padding: 13px;
}
.reserve-pop-up-0 .rented-appartment-sec .decription-wrapper .btn:hover {
  outline: 2px solid #3b4b35;
}
.reserve-pop-up-0 .featurd ul#featured {
  padding: 0px;
  list-style: none;
}
.reserve-pop-up-0 .featurd ul#featured li {
  display: inline-block;
  padding: 8px 20px;
}
.reserve-pop-up-0 .featurd ul#featured li span {
  color: #707070;
  font-size: 17px;
}
.reserve-pop-up-0 ul#contact-info {
  padding: 0px;
  list-style: none;
  display: flex;
}
.reserve-pop-up-0 ul#contact-info li i {
  color: #707070;
  margin-right: 10px;
}

.reserve-pop-up-0 ul#contact-info li {
  margin-right: 10px;
  color: #707070;
  font-size: 14px;
}
.reserve-pop-up-0 .modal-dialog {
  min-width: 80%;
}
.reserve-pop-up-0 .close-btn {
  position: absolute;
  right: -24px;
  top: -18px;
  cursor: pointer;
}
.reserve-pop-up-0 {
  background: #ffffffc2;
}
.reserve-pop-up-0 span.date {
  color: #454d40;
  font-size: 20px;
  font-weight: 500;
}
.reservation .rever-wrap .pill {
  border: 1px solid #edf3ed;
  border-radius: 10px;
}
/* resposive starts here */
@media (max-width: 1100px) {
  .profile div#v-pills-tab a {
    margin: 16px 0px;
    font-size: 19px;
  }
  .date-details p {
    padding-left: 10px;
    border-left: 1px solid;
    font-size: 13px;
  }
}
@media (max-width: 991px) {
  .addcartwrapper .profile-wrap {
    margin-top: 50px;
  }
}
@media (max-width: 480px) {
  .reservation .resrve-wrapper img {
    width: 100%;
    margin-bottom: 20px;
  }
  .reservation .rever-wrap .content-wrapper {
    margin-bottom: 20px;
  }
}
