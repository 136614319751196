.job-sec {
    padding: 100px 0px;
}
.job-sec h2 {
    color: #313F2B;
    font-weight: 400;
}
.job-sec .time-and-price {
    display: flex;
    justify-content: space-between;
}
.job-sec .btn {
    padding: 8px 17px;
    background: #313F2B;
    color: #fff;
}
.job-sec h4.date {
    color: #636060;
    font-size: 13px;
    font-weight: 400;
    text-align: end;
}
.time-and-price h5 {
    color: #636060;
    font-size: 18px;
    font-weight: 400;
}
.time-and-price h5 i {
    color: #313F2B;
    margin-right: 10px;
    font-size: 20px;
}
.jobs {
    padding: 30px 0px;
    align-items: center;
}
.jobs {
    padding: 30px 0px;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
}
.jobs .content-wrapper {
    border-right: 1px solid #707070;
}
.job-sec .view-and-date {
    border-left: 1px solid #707070;
}