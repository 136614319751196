.payment {
  padding-top: 150px;
  padding-bottom: 100px;
}
.payment-break-down-wrapper ul {
  list-style: none;
  padding: 0px;
}
.payment-break-down-wrapper ul li {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
}
.payment-break-down-wrapper ul.duties {
  margin-bottom: 43px;
}
.payment-break-down-wrapper {
  background: #f9f9f9;
  width: 100%;
  padding: 26px;
  margin-top: 30px;
  border-radius: 13px;
}
.payment-break-down-wrapper h3 {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 25px;
}
.payment-break-down-wrapper ul.duties li span {
  color: #707070;
  font-size: 17px;
}
.payment-break-down-wrapper ul.total {
  margin-bottom: 0px;
  padding-top: 26px !important;
  display: block;
  border-top: 1px solid #c7c3c3;
}
.payment-break-down-wrapper ul.total li span {
  font-size: 19px;
  font-weight: 500;
}
.promo-code-wrapper button.form-btn {
  background: #ffcc00;
  width: 100%;
  border: 0px;
  height: 45px;
  border-radius: 5px;
  color: #313f2b;
  font-size: 17px;
  font-weight: 500;
}
.promo-code-wrapper input.form-control {
  height: 45px;
}
.promo-code-wrapper input.form-control::placeholder {
  color: #c4c0c7;
}
.promo-code-wrapper label {
  margin-bottom: 10px;
}

.payment-form-wrapper input[type="radio"] {
  display: none;
}
.payment input[type="text"] {
  height: 51px;
}

.payment input[type="radio"]:checked + label img {
  border: 1px solid #f35f63;
}
.payment button.btn-card {
  background: #f35f63;
  border: 0px;
  color: #fff;
  padding: 14px 51px;
  font-weight: bold;
  font-size: 13px;
  font-family: "Spartan";
}
.payment .card-info {
  display: flex;
  align-items: center;
}
.editss {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 40px;
}
.payment label.container {
  padding: 0;
}
.payment label.container span.checkmark .card-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #707070;
  padding: 10px 20px;
  position: relative;
}
.payment label.container input:checked ~ span.checkmark .card-options {
  box-shadow: 0px 0px 0px 2px #f35f63;
  border-color: #fff;
}
.payments label.container span.checkmark .card-options .visa-card {
  display: flex;
  align-items: center;
}
.payment
  label.container
  span.checkmark
  .card-options
  .visa-card
  .form-group
  label {
  font-size: 8px;
  color: #000000;
}
.payment
  label.container
  span.checkmark
  .card-options
  .visa-card
  .form-group
  .form-control {
  border: 0;
  font-size: 18px;
  color: #343a40;
  height: 20px;
  padding: 0;
}
.payment label.container span.checkmark .card-options .visa-card .img-box {
  margin-right: 20px;
}
.payment label.container span.checkmark .card-options .action-btns {
  text-align: right;
}
.payment
  label.container
  span.checkmark
  .card-options
  .action-btns
  button.delete {
  background: transparent;
  border: 0;
  padding-bottom: 10px;
}
.payment
  label.container
  span.checkmark
  .card-options
  .action-btns
  button.delete
  i {
  color: #000;
  font-size: 20px;
  padding-bottom: 16px;
}
.payment
  label.container
  span.checkmark
  .card-options
  .action-btns
  .use-default
  label {
  font-size: 15px;
  color: #1f2c35;
  padding-right: 60px;
}
.payment
  label.container
  span.checkmark
  .card-options
  .action-btns
  .use-default
  span.check-box {
  width: 18px;
  height: 18px;
  background: #fff;
  position: absolute;
  right: 24px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 0px 1px #f35f63;
}
.payment
  label.container
  input:checked
  ~ span.checkmark
  .card-options
  .action-btns
  .use-default
  span.check-box {
  background: #f35f63;
}

.payment-content-wrapper h3 {
  font-size: 25px;
  font-weight: 500;
  color: #373a36;
  margin-bottom: 30px;
}
.payment-content-wrapper p {
  color: #373a36;
  font-size: 15px;
}
.payment-form-wrapper label {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.payment .agree-wrapper {
  background: #f9f9f9;
  padding: 23px;
  border-radius: 11px;
}
.agree-wrapper span {
  color: #313f2b;
}
.agree-wrapper label.custom-control-label {
  color: #bebebe;
  font-weight: 400;
  margin-top: 10px;
}

.payment-form-wrapper h4 {
  font-size: 20px;
  color: #313131;
  font-weight: 500;
}

.payment-form-wrapper .custom-checkbox .custom-control-label::before {
  border-radius: 5px;
  border: 2px solid #bebebe;
  background: #f9f9f9;
  padding: 8px;
  margin-left: -2px;
  outline: none;
}
.payment-form-wrapper .custom-checkbox .custom-control-label:focus {
  outline: none;
}
.payment-form-wrapper
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  content: "\f00c";
  background-image: none;
  background-color: transparent !important;
  font-family: "FontAwesome";
  margin-top: 0px;
  font-size: 14px;
  margin-left: 1px;
  outline: none;
}

.payment .confir p {
  margin-left: 28px;
}
.payment .confir p a {
  text-decoration: underline;
  color: #737d6f;
}

.payment-form-wrapper .form-btn {
  background: #313f2b;
  border: 0px;
  color: #fff;
  padding: 12px 34px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 6px;
}
.payment .payment-form-wrapper input.form-control {
  height: 45px;
  border: 1px solid #9b9b9b;
}
