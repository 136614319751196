.Rentalappartments {
  padding: 100px 0px;
}
/* map css starts here */
.furnished .mapouter {
  position: relative;
  text-align: right;
  height: 840px;
  width: 100%;
}
.furnished .gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 840px;
  width: 100%;
  border-radius: 20px;
}
/* map css end here */
.card-wrapper {
  background: #f9f9f9;
  padding: 0px 0px 20px 0px;
  box-shadow: none;
  border-radius: 20px;
}
.card-content-wrapper .description span.green {
  color: #313f2b;
}
.furnished ul.specification li img {
  transform: scale(0.8);
}
.furnished ul.specification li {
  font-size: 16px;
  font-family: "brown-reg";
}
.RentalCard .card-wrapper:hover button.slick-arrow,
.RentalCard .card-wrapper:hover ul.slick-dots {
  opacity: 1 !important;
}

.RentalCard .card-wrapper button.slick-arrow,
.RentalCard .card-wrapper ul.slick-dots {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.filter-wrap .button-wrapper .btn {
  padding: 15px 25px;
  border-radius: 12px;
  background: #f9f9f9;
  color: #454d40;
}
.filter-wrap .button-wrapper {
  margin-bottom: 30px;
}
.filter-wrap .button-wrapper .btn span {
  color: #acacac;
}
.filter-wrap .button-wrapper .btn .green {
  color: #454d40;
}
.filter-wrap .button-wrapper {
  display: flex;
  justify-content: space-between;
}
.filter-wrap .button-wrapper .btn-yellow {
  background: #ffcc00;
}

.filter-wrap .button-wrapper .btn-yellow span {
  color: #454d40 !important;
}
.filter-wrap .button-wrapper .btn:hover {
  outline: none;
}
.furnished .select-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 300px;
  margin: 0px auto;
  margin-right: 0px;
  margin-bottom: 20px;
  color: #454d40;
  font-weight: 500;
}
.furnished .select-wrapper select.form-control {
  width: unset;
  padding: 0px;
  border: 0px;
  font-weight: 500;
}
.furnished .select-wrapper label {
  margin: 0px;
  color: #454d40;
  font-weight: 500;
}
.box-wrapper {
  position: relative;
  margin-top: 20px;
}
.box-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #313f2bf7;
  color: #fff;
  padding: 20px;
  border-radius: 13px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.box-content i {
  font-size: 43px;
}
.box-content h4 {
  font-size: 17px;
  font-weight: 500;
}
.furnished .pagination {
  justify-content: end;
}
.furnished .arrow a.page-link {
  background: #fff;
  border: 0px;
}
.furnished .arrow a.page-link i {
  color: #313f2b;
  font-size: 22px;
}
.furnished ul.pagination li.page-item a.page-link {
  padding: 3px 8px;
  margin: 0px 2px;
  border-radius: 5px;
  background: #dedede;
  color: #fff;
}
.furnished ul.pagination li.page-item.active a.page-link {
  border: 1px solid #31402c !important;
  background: #fff !important;
  color: #313f2b;
  font-weight: 500;
}
.furnished .arrow a.page-link {
  background: transparent !important;
}

/* responsive */
@media (max-width: 1100px) {
  .filter-wrap .button-wrapper .btn {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .filter-wrap .button-wrapper {
    display: inline-block;
  }
  .box-wrapper .img-wrapper img {
    width: 100%;
  }
}
