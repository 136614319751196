.rooms {
  padding: 60px 0px;
}
.rooms h3 {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 20px;
}
.rooms h4 {
  font-weight: 500;
  color: #373a36;
}
/* map css starts here */
.map {
  padding: 60px 0px;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 655px;
  width: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 655px;
  width: 100%;
  border-radius: 20px;
}
/* map css end here */
.furnished h2.near-by {
  color: #373a36;
  font-weight: 600;
  font-size: 37px;
  margin-bottom: 30px;
}
/* nearby sec */
/* slider starts here */
.rented-appartment-sec {
  padding: 60px 0px;
}
.rented-appartment-sec .slick-dots {
  bottom: 10px;
}
.rented-appartment-sec .slick-prev::before {
  content: "\f104";
  font-family: "FontAwesome";
  font-size: 27px;
  background: #fff;
  padding: 4px 12px;
  border-radius: 10px;
  color: #313f2b;
  opacity: 0.7;
  font-weight: 700;
}
.rented-appartment-sec .slick-next::before {
  content: "\f104";
  font-family: "FontAwesome";
  font-size: 27px;
  background: #fff;
  padding: 4px 12px;
  border-radius: 10px;
  color: #313f2b;
  opacity: 0.7;
  font-weight: 700;
}
.rented-appartment-sec .button-wrapper button.btn {
  padding: 13px 31px;
  border-radius: 7px;
  width: 49%;
  color: #454d40;
  font-size: 20px;
  font-weight: 500;
  background: #f9f9f9;
}
.rented-appartment-sec .button-wrapper {
  display: flex;
  justify-content: space-between;
}
.rented-appartment-sec .property-name-and-night {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rented-appartment-sec .property-name-and-night h5 {
  font-size: 20px;
  color: #3b4b35;
  font-weight: 400;
}
.rented-appartment-sec .property-name-and-night h3 {
  color: #373a36;
  font-size: 30px;
  font-weight: 600;
}
.rented-appartment-sec .content-wrapper span {
  color: #707070;
  font-size: 20px;
}
.rented-appartment-sec .content-wrapper .price {
  color: #707070;
  font-size: 20px;
  margin-top: 10px;
}
.rented-appartment-sec .decription-wrapper h4 {
  color: #454d40;
  font-weight: 500;
  font-size: 20px;
}
.rented-appartment-sec .decription-wrapper p {
  color: #373a36;
  font-size: 14px;
}
.rented-appartment-sec .decription-wrapper .btn {
  background: #3b4b35;
  font-size: 23px;
  color: #fff;
  font-weight: 600;
  width: 100%;
  border-radius: 8px;
  padding: 9px;
}
.rented-appartment-sec .decription-wrapper .btn:hover {
  outline: 2px solid #3b4b35;
}
.featurd ul#featured {
  padding: 0px;
  list-style: none;
}
.featurd ul#featured li {
  display: inline-block;
  padding: 19px 4px;
}
.featurd ul#featured li span {
  color: #707070;
  font-size: 17px;
}
ul#contact-info {
  padding: 0px;
  list-style: none;
  display: flex;
}
ul#contact-info li i {
  color: #707070;
  margin-right: 10px;
}

ul#contact-info li {
  margin-right: 10px;
  color: #707070;
  font-size: 14px;
}
/* modal css */
.reserve-pop-up img.close-btn {
  position: absolute;
  right: -19px;
  margin-top: -15px;
  cursor: pointer;
}
.reserve-pop-up .modal-body {
  padding: 35px;
}
.reserve-pop-up button.form-btn {
  background: #3b4b35;
  padding: 10px 17px;
  margin: 0px auto;
  margin-right: 0px;
  display: block;
  margin-top: 20px;
  font-size: 15px;
  color: #fff;
}
.reserve-pop-up .modal-content {
  border-radius: 9px;
  border: 2px solid #707070;
}
.reserve-pop-up h4 {
  color: #313131;
  font-weight: 500;
  font-size: 23px;
}
.reserve-pop-up label {
  color: #000000;
  font-size: 16px;
}
.reserve-pop-up .modal-form-wrapper .form-group {
  margin-top: 24px;
}
.reserve-pop-up .modal-form-wrapper .form-control {
  border: 1px solid #9b9b9b;
  height: 46px;
}
.reserve-pop-up .modal-form-wrapper .form-control::placeholder {
  color: #9b9b9b;
  font-size: 19px;
}
.reserve-pop-up.modal {
  background: #ffffffd1 !important;
}
.dated-from-to-where-wrap input.form-control {
  border: 0px;
}
.dated-from-to-where-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.form-to-where-wrapper .cost-head {
  display: flex;
  justify-content: space-between;
  margin: 23px 0px;
}
.form-to-where-wrapper .cost-head h3 {
  color: #3b4b35;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
}
.dated-from-to-where-wrap {
  border: 1px solid #9b9b9b;
  border-radius: 4px;
  margin-bottom: 11px;
}
/* modal css */

/* responsive css starts here */
@media (max-width: 480px) {
  ul#contact-info {
    flex-direction: column;
  }
  .rented-appartment-sec .property-name-and-night {
    display: block;
  }
}
