.Contact-form-wrapper {
  padding: 100px 0px;
}

.Contact-form-wrapper h2 {
  color: #313f2b;
  font-size: 41px;
  font-weight: 400;
  line-height: 46px;
}

.Contact-form-wrapper p {
  color: #363636;
}
/* map css starts here */
.mapouter {
  position: relative;
  text-align: right;
  height: 643px;
  width: 600px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 643px;
  width: 600px;
}
.Contact-form-wrapper .form-wrapper input {
  border: 1px solid #b8b8b8;
  border-radius: 0px;
  padding: 22px 10px;
  margin-bottom: 15px;
  border-radius: 7px;
}
.Contact-form-wrapper .form-wrapper select {
  border: 1px solid #b8b8b8;
  border-radius: 0px;
  margin-bottom: 15px;
  height: 46px;
  border-radius: 7px;
}
.Contact-form-wrapper .form-wrapper select::placeholder {
  color: #313f2b;
}
.Contact-form-wrapper .form-wrapper textarea {
  border: 1px solid #b8b8b8;
  border-radius: 0px;
  padding: 22px 10px;
  border-radius: 7px;
}
.Contact-form-wrapper .form-wrapper button.form-btn {
  background: #313f2b;
  color: #fff;
  padding: 14px 131px;
  margin-top: 23px;
  font-weight: 500;
  border: 2px solid #fff;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
}
.Contact-form-wrapper .form-wrapper button.form-btn:hover {
  outline: 2px solid #313f2b;
  border-radius: 0px;
}
/* icons info section starts here */
.icons-sec {
  padding-bottom: 100px;
  padding-top: 30px;
}
.info-wrapper h4 {
  color: #707070;
  font-size: 24px;
  font-weight: 600;
}

.info-wrapper p {
  color: #888888;
  font-size: 15px;
}
.info-wrapper {
  border-right: 1px solid #a4a4a4;
}
.info-wrapper.loc {
  border: none;
}
/* responsive starts here */
@media (max-width: 800px) {
  .info-wrapper {
    border-right: 0px;
    border-bottom: 1px solid #a4a4a4;
    padding: 20px;
  }
}
@media (max-width: 480px) {
  .Contact-form-wrapper h2 {
    font-size: 35px;
  }
}
@media (max-width: 375px) {
  .Contact-form-wrapper .form-wrapper button.form-btn {
    padding: 14px 112px;
  }
}
