.works {
  padding: 100px 0px;
}
.works-list {
  position: relative;
  padding: 28px 0px !important;
}
.works h2 {
  color: #313f2b;
  font-size: 33px;
}
.works-list span.number {
  background: #f1f1f1;
  padding: 5px 21px;
  border-radius: 4px;
  font-size: 34px;
  color: #313f2b;
  display: inline;
  line-height: 47px;
}
.works-list h3 {
  font-size: 33px;
  color: #313f2b;
  font-weight: 400;
}
.works-list p {
  color: #707070;
  font-size: 17px;
}
.works-list .col-lg-1 span::before {
  content: "";
  height: 155px;
  width: 2px;
  position: absolute;
  background: #f1f1f1;
  left: 46%;
  z-index: -1;
}
.works-list:last-child span::before {
  background: transparent;
}
.works-list::after {
  content: url("../img/works-line.png");
  position: absolute;
  left: 4%;
  top: 76%;
}
.works-list:last-child::after {
  display: none;
}
/* refer a friend page css starts */
.copy-text button {
  background: transparent;
  border: 0px;
}
.copy-text h4 {
  color: #313f2b;
  font-weight: 400;
}
.copy-text h4 a {
  color: #707070;
}
.copy-text h4 {
  font-size: 16px;
}
.copy-text {
  align-items: baseline;
}
.link input {
  background: #f2f2f2;
  padding: 12px 15px;
  border-radius: 10px;
  color: #313f2b;
  font-size: 16px;
  margin-top: 14px;
  border: none;
}
.link input:focus {
  outline: none;
}
.ref-list {
  position: relative;
  padding: 51px 0px !important;
}
.ref-list .col-lg-1 span::before {
  content: "";
  height: 179px;
  width: 2px;
  position: absolute;
  background: #f1f1f1;
  left: 46%;
  z-index: -1;
}
.ref-list:last-child span::before {
  display: none;
}
/* refer a friend page css ends */
@media (max-width: 1280px) {
  .works-list::after {
    left: -1%;
    transform: scale(0.9);
  }
}
@media (max-width: 1100px) {
  .works-list span.number {
    padding: 5px 13px;
  }
}
@media (max-width: 991px) {
  .works-list::after {
    display: none;
  }
  .works-list .col-lg-1 span::before {
    display: none;
  }
  .works-list span.number {
    margin-bottom: 30px;
    display: inline-block;
    padding: 10px 20px;
  }
}
